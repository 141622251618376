import { Text } from '@/atoms';

const PriceSummaryHeader = ({ priceSummaryHeader = [] }) => (
  <div className='w-full'>
    <div className='flex gap-5 text-xs font-semibold text-silver uppercase py-5 px-4 rounded-t-lg border-b border-platinum bg-planner-cart-head'>
      {priceSummaryHeader.map((props, index) => (
        <Text
          key={`${props.content}-${index}`}
          {...props}
        />
      ))}
    </div>
  </div>
);

export default PriceSummaryHeader;
