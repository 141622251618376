import { Button } from '@/atoms';
import { PRODUCT_STATUS, staticMediaStoreBaseURL } from '@/config/common';
import { PAGE_NAME } from '@/lib/gtm';
import { handleAddToCart } from '@/services/product.service';

const PublishedItemButton = ({
  createCart,
  isQuantityInvalid,
  product,
  quantity,
  revertQuantityToMin,
  setShowAddedToCartAlert,
  showQuantityRangeAlert = false,
  updateCartInPortalHeader
}) => (
  <Button
    {...{
      children: 'Add to Cart',
      className: `text-white bg-gradient-to-r from-light-orange via-dark-orange to-rose py-2 h-12 rounded-lg text-smfont-medium`,
      iconGroupClassName: 'reduce-gap',
      iconHeight: 18,
      iconPosition: 'Left',
      iconUrl: `${staticMediaStoreBaseURL}/icons/cart-white.svg`,
      iconWidth: 18,
      disabled: showQuantityRangeAlert,
      onClick: () =>
        !showQuantityRangeAlert &&
        handleAddToCart({
          createCart,
          isQuantityInvalid,
          pageName: PAGE_NAME.PDP.label,
          product,
          quantity,
          revertQuantityToMin,
          setShowAddedToCartAlert,
          updateCartInPortalHeader
        }),
      type: 'submit'
    }}
  />
);

const UnPublishedItemButton = () => (
  <Button
    {...{
      children: 'Unavailable',
      className: `text-white bg-gradient-to-r from-light-orange via-dark-orange to-rose py-2 h-12 rounded-lg w-full text-smfont-medium opacity-50 cursor-not-allowed`,
      disabled: true
    }}
  />
);

const AddToCartButtonType = {
  [PRODUCT_STATUS.PUBLISHED]: PublishedItemButton,
  [PRODUCT_STATUS.UNPUBLISHED]: UnPublishedItemButton
};

const NRFQProductAddToCart = ({
  createCart,
  isQuantityInvalid,
  minBookable,
  product,
  quantity: rawQuantity,
  revertQuantityToMin,
  setShowAddedToCartAlert,
  showQuantityRangeAlert,
  updateCartInPortalHeader
}) => {
  const quantity = rawQuantity || minBookable;
  const AddToCartButton = AddToCartButtonType[product.status];

  return (
    <div className='flex flex-1'>
      <div className='w-full'>
        <AddToCartButton
          {...{
            createCart,
            isQuantityInvalid,
            updateCartInPortalHeader,
            product,
            quantity,
            revertQuantityToMin,
            setShowAddedToCartAlert,
            showQuantityRangeAlert
          }}
        />
      </div>
    </div>
  );
};

export default NRFQProductAddToCart;
