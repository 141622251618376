import {
  CloseIcon,
  ImageCarousel,
  ItemCommonDetails,
  RichTextEditor,
  Text
} from '@/atoms';
import { PRODUCT_STATUS } from '@/config/common';
import { getSerializedRichText } from '@/helpers/editor';
import { parseFormatPriceNumberValueFromAPI } from '@/helpers/formatNumber';
import { useIsMobile } from '@/helpers/screenResolutionHelper';

const ItemImage = ({ isMobile, mediaList, openImageModal }) => (
  <div
    className={`w-full ${
      isMobile ? 'h-50' : 'h-528'
    } relative image-bid-detail`}
  >
    <ImageCarousel
      {...{
        containerStyle: `${isMobile ? 'h-50' : 'h-528'} w-full`,
        excludeImageHeightFromContainer: true,
        images: mediaList,
        isThumbnail: false,
        layout: 'fill',
        objectFit: 'cover',
        openImageModal,
        style: 'rounded-l-xl'
      }}
    />
  </div>
);

const ItemDetail = ({
  openImageModal,
  itemMediaForCarousel,
  productName,
  productNotes,
  productStatus,
  quantity,
  setItemInDetailView,
  unitListedPrice
}) => {
  const [isMobile] = useIsMobile();
  const itemTextClass =
    productStatus === PRODUCT_STATUS.UNPUBLISHED
      ? 'text-error-base'
      : 'text-rich-black';

  return (
    <div className='relative item-detail'>
      <div className='flex flex-col gap-3 shadow pb-5 rounded-lg relative'>
        <CloseIcon
          {...{
            className:
              'mt-3 justify-end cursor-pointer -ml-8 absolute z-10 left-full displayFlex',
            icon: 'close-gray-white-icon.svg',
            onClick: () => setItemInDetailView(null)
          }}
        />
        <ItemImage
          {...{
            isMobile,
            mediaList: itemMediaForCarousel,
            openImageModal
          }}
        />
        <div className='flex flex-col gap-2 mx-4'>
          <Text
            {...{
              className: `${itemTextClass} font-semibold text-base md:text-lg`,
              content: productName
            }}
          />
          <ItemCommonDetails
            {...{
              containerClass: itemTextClass,
              priceLabel: parseFormatPriceNumberValueFromAPI(
                unitListedPrice * quantity
              ),
              quantity
            }}
          />

          {getSerializedRichText(productNotes) && (
            <div className={itemTextClass}>
              <Text
                content='Details: '
                className='text-xs md:text-base text-rich-black font-medium'
              />
              <RichTextEditor
                {...{
                  editorStyle:
                    'text-xs md:text-base font-light whitespace-pre-wrap mt-2',
                  readOnly: true,
                  values: productNotes
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
