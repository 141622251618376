import Link from 'next/link';
import { Fragment } from 'react';

import { Text, Uuid } from '@/atoms';
import { convertToStandardDateFormat } from '@/lib/time';

const CartEventInfo = ({
  zendeskTicketUrlPrefix,
  userEventSummary: {
    id,
    zendeskTicketId,
    userEventDetails: [userEventDetails] = []
  } = {}
}) => {
  if (!id) {
    return '';
  }

  const {
    eventTitle,
    eventAddress,
    eventContactEmail,
    checkoutEvent,
    eventContactName,
    opsUser,
    eventStartTime,
    eventDate
  } = userEventDetails;

  return (
    <Fragment>
      <div className='flex flex-col md:flex-row justify-start gap-4 pt-6 border-t border-platinum'>
        <Text content={eventTitle || 'NA'} />
      </div>
      <div className='flex flex-col md:flex-row gap-2 md:gap-0 lg:gap-20 pt-2 md:pt-5 pb-2 text-sm'>
        <div className='flex flex-col gap-2 md:gap-4'>
          <div className='flex flex-col md:flex-row gap-2 md:gap-4 '>
            <Uuid
              label={'Id: '}
              labelClass='text-silver self-start md:self-center w-36'
              value={id}
              valueClass='cursor-pointer text-base self-start md:self-center'
            />
          </div>
          <div className='flex flex-col md:flex-row  gap-2 md:gap-4'>
            <Text
              content='Event Date: '
              className='text-silver self-start md:self-center w-36'
            />
            <Text
              content={convertToStandardDateFormat(eventDate) || 'NA'}
              className='text-base self-start md:self-center w-72'
            />
          </div>
          <div className='flex flex-col md:flex-row  gap-2 md:gap-4'>
            <Text
              content='Contact Email: '
              className='text-silver self-start md:self-center w-36'
            />
            <Text
              content={eventContactEmail || 'NA'}
              className='text-base self-start md:self-center'
            />
          </div>
        </div>
        <div className='flex flex-col gap-2 md:gap-4'>
          <div className='flex flex-col md:flex-row  gap-2 md:gap-4'>
            <Text
              content='Zendesk Ticket: '
              className='text-silver self-start md:self-center w-36'
            />
            <Link href={`${zendeskTicketUrlPrefix}${zendeskTicketId}`}>
              <a
                target='_blank'
                rel='noreferrer'
                data-toggle='tooltip'
                title='ZendeskTicketId'
                className='text-base'
              >
                <Text
                  content={zendeskTicketId}
                  className='text-blue cursor-pointer'
                />
              </a>
            </Link>
          </div>
          <div className='flex flex-col md:flex-row  gap-2 md:gap-4'>
            <Text
              content='Event Type: '
              className='text-silver self-start md:self-center w-36'
            />
            <Text
              content={checkoutEvent?.event?.name || 'NA'}
              className='text-base self-start md:self-center'
            />
          </div>
          <div className='flex flex-col md:flex-row  gap-2 md:gap-4'>
            <Text
              content='Contact Name: '
              className='text-silver self-start md:self-center w-36'
            />
            <Text
              content={eventContactName || 'NA'}
              className='truncate w-40 text-base self-start md:self-center'
            />
          </div>
        </div>
        <div className='flex flex-col gap-2 md:gap-4'>
          <div className='flex flex-col md:flex-row  gap-2 md:gap-4'>
            <Text
              content='Planner name: '
              className='text-silver self-start md:self-center w-36'
            />
            <Text
              content={opsUser?.name || 'NA'}
              className='text-base self-start md:self-center'
            />
          </div>
          <div className='flex flex-col md:flex-row  gap-2 md:gap-4'>
            <Text
              content='Event Start Time: '
              className='text-silver self-start md:self-center w-36'
            />
            <Text
              content={eventStartTime || 'NA'}
              className='text-base self-start md:self-center'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-col md:flex-row  mt-2'>
        <Text
          content='Location: '
          className='text-silver self-start md:self-center w-40 text-sm'
        />
        <Text
          content={eventAddress || 'NA'}
          className='text-base self-start md:self-center'
        />
      </div>
    </Fragment>
  );
};

export default CartEventInfo;
