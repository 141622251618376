import { Button, CTA, UnSavedChangesErrorMessage } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const CartItemDetailCTA = ({
  cartItemViewEditMode,
  discardChanges,
  handleSubmit,
  isCartEditable,
  setCartItemViewEditMode,
  showUnSavedChangesErrorMessage = false,
  updateCartItemDetail
}) => (
  <div className='flex justify-between gap-4'>
    <UnSavedChangesErrorMessage {...{ showUnSavedChangesErrorMessage }} />
    <div className='flex gap-4'>
      <CTA
        {...{
          children: cartItemViewEditMode ? 'Close' : 'Cancel',
          buttonColor: 'bg-gray',
          className: 'py-2 h-9 text-sm font-medium px-6',
          onClick: discardChanges
        }}
      />
      {isCartEditable && (
        <Button
          {...{
            children: cartItemViewEditMode ? 'Edit' : 'Save',
            width: 'w-20',
            className:
              'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white px-6 py-2 rounded-lg text-sm font-medium',
            iconGroupClassName: 'reduce-gap',
            iconHeight: 16,
            iconPosition: cartItemViewEditMode ? 'Left' : null,
            iconUrl: `${staticMediaStoreBaseURL}/icons/edit-icon-white.svg`,
            iconWidth: 16,
            onClick: cartItemViewEditMode
              ? () => setCartItemViewEditMode(!cartItemViewEditMode)
              : handleSubmit(updateCartItemDetail)
          }}
        />
      )}
    </div>
  </div>
);

export default CartItemDetailCTA;
