import ProgressBar from '@ramonak/react-progress-bar';

import { Button, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const EventProfileProgressBar = ({
  eventDescription,
  eventHeader: { eventTitle, buttonText, onClickCTA },
  progressBarPercentage = 0
}) => (
  <div className='flex flex-col gap-4 w-100 bg-border rounded-lg text-white p-4'>
    <div className='flex gap-4'>
      <Text
        {...{
          className: 'self-center text-lg font-medium',
          content: eventTitle
        }}
      />
      <Button
        {...{
          children: buttonText,
          className:
            'text-sm px-0 py-1 mx-2 md:mx-0 rounded-md font-medium border border-white',
          iconGroupClassName: 'reduce-gap',
          iconHeight: '15',
          iconPosition: 'Left',
          iconUrl: `${staticMediaStoreBaseURL}/icons/view-icon.svg`,
          iconWidth: '15',
          onClick: onClickCTA,
          width: 'w-20'
        }}
      />
    </div>
    <Text
      {...{
        className:
          'text-xs font-medium bg-lightgray p-2 rounded event-progress-bar-content',
        content: eventDescription
      }}
    />

    <div className='flex gap-2 w-full mb-2'>
      <Text
        {...{
          HtmlTag: 'span',
          content: `${progressBarPercentage} %`,
          className: 'text-base font-semibold w-22 progress-bar-label'
        }}
      />
      <div className='w-full event-profile-progress-bar'>
        <ProgressBar
          {...{
            bgColor: '#222222',
            completed: progressBarPercentage,
            customLabel: ' ',
            width: '100%'
          }}
        />
      </div>
    </div>
  </div>
);

export default EventProfileProgressBar;
