import { Button } from '@/atoms';

const buttonClass = ' border-gray-200 bg-gray h-8 w-1/5 focus:outline-none';

const SpinnerButton = ({ onClick, content, disabled, style = '' }) => (
  <Button
    {...{
      children: content,
      className: `${style} ${buttonClass}`,
      disabled,
      onClick
    }}
  />
);

export default SpinnerButton;
