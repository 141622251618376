import Image from 'next/image';
import { Modal, ModalBody } from 'reactstrap';

import { Button, CloseIcon, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const PDFDownloadConfirmationModal = ({
  onClickDownloadPDF,
  onClickCopyQuoteLink,
  showPDFDownloadConfirmationModal,
  setShowPDFDownloadConfirmationModal
}) => {
  const toggleModal = () =>
    setShowPDFDownloadConfirmationModal(!showPDFDownloadConfirmationModal);

  return (
    <Modal
      isOpen={showPDFDownloadConfirmationModal}
      toggle={toggleModal}
    >
      <ModalBody
        className='z-120 bg-white m-18 w-full md:w-41
    min-h-96 mx-auto px-8 md:rounded-lg pb-2 pt-4'
      >
        <div className='flex-1 flex flex-row-reverse relative left-4'>
          <CloseIcon {...{ onClick: toggleModal }} />
        </div>
        <div className='flex flex-col gap-7'>
          <Text
            {...{
              className: 'text-center text-xl font-semibold pb-5',
              content: 'Step into the Future: Say Goodbye to PDFs!'
            }}
          />
          <Image
            alt='superhero icon'
            height={261}
            src={`${staticMediaStoreBaseURL}/icons/superhero.svg`}
            width={462}
          />
          <div className='flex flex-col gap-3 text-center'>
            <Text
              {...{
                className: 'text-nero font-semibold',
                content: 'Still downloading PDFs?'
              }}
            />
            <Text
              {...{
                className: 'text-dim-gray font-semibold',
                content:
                  'Ditch the Stone Age and enjoy the dynamic, interactive experience of real-time quoting'
              }}
            />
          </div>
        </div>
        <div className='flex flex-col gap-4 my-4'>
          <Button
            {...{
              children: 'Step into the Future! Get Web Quote link',
              className:
                'bg-brand-gradient text-white h-12.5 py-4 rounded-md text-sm font-medium leading-5 w-full md:mb-0',
              onClick: onClickCopyQuoteLink
            }}
          />
          <Button
            {...{
              children: 'Anyways, I prefer PDF!',
              className: `bg-white border border-brand text-brand h-12.5 py-4 rounded-md text-sm font-medium leading-5 w-full md:mb-0`,
              onClick: onClickDownloadPDF
            }}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PDFDownloadConfirmationModal;
