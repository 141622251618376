import Image from 'next/image';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Dropdown, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const TerminateEventLabelAndEditIcon = ({
  leadExitReason,
  setShowDropDown
}) => (
  <div className='flex gap-2'>
    <Text
      {...{
        className:
          'bg-brand-gradient text-white shadow-card font-medium text-sm py-3 flex-1 rounded text-left px-3',
        content: `Terminated Event : ${leadExitReason.name}`
      }}
    />
    <div
      className='flex justify-center bg-white shadow-update-event rounded-md cursor-pointer w-10 h-11'
      onClick={() => setShowDropDown(true)}
    >
      <Image
        alt='editIcon'
        height={20}
        src={`${staticMediaStoreBaseURL}/icons/edit-red-with-frame.svg`}
        width={20}
        className='self-center '
      />
    </div>
  </div>
);

const TerminateEvent = ({
  leadExitReason,
  leadExitReasonErrorMessage,
  leadExitReasons,
  setLeadExitReasonErrorMessage,
  updateLeadExitReasonAPI
}) => {
  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue
  } = useForm({
    defaultValues: {
      leadExitReasonId: leadExitReason?.id
    }
  });

  const [showDropDown, setShowDropDown] = useState(false);

  const onSubmit = async ({ leadExitReasonId }) => {
    await updateLeadExitReasonAPI({ leadExitReasonId });
  };

  const leadExitOptions = [
    {
      label: 'select lead termination reason',
      value: null
    },
    ...leadExitReasons.map(({ name, id }) => ({
      label: name,
      value: id
    }))
  ];

  return (
    <div className='flex flex-col gap-2 mt-2'>
      {leadExitReason ? (
        <TerminateEventLabelAndEditIcon
          {...{
            leadExitReason,
            leadExitReasonErrorMessage,
            setShowDropDown
          }}
        />
      ) : (
        <Button
          {...{
            children: 'Terminate Event',
            className:
              'bg-lightgray border border-brand text-brand hover:text-white hover:bg-brand shadow-card font-medium text-sm py-3 rounded',
            onClick: () => setShowDropDown(true)
          }}
        />
      )}
      {showDropDown && (
        <>
          <div className='mt-2'>
            <Dropdown
              {...{
                className:
                  'shadow-card terminate-event-dropdown border border-platinum hover:border-brand cursor-pointer rounded text-sm',
                clearErrors,
                control,
                dbName: 'leadExitReasonId',
                errors,
                getValues,
                isSearchable: true,
                isClearable: true,
                name: 'leadExitReasonId',
                onChange: (e) => {
                  setValue('leadExitReasonId', e ? e.value : null);
                  clearErrors();
                },
                options: leadExitOptions,
                placeholder: 'Select Reason for Termination',
                register,
                setValue
              }}
            />
          </div>
          {leadExitReasonErrorMessage && (
            <div className='mt-1 w-full text-xs text-red-500'>
              {leadExitReasonErrorMessage}
            </div>
          )}
          <div className='flex gap-4 mt-3'>
            <Button
              {...{
                children: 'Cancel',
                className:
                  'bg-gray text-nero shadow-card font-medium text-sm py-3 rounded',
                onClick: () => {
                  reset();
                  setShowDropDown(false);
                  setLeadExitReasonErrorMessage(null);
                }
              }}
            />
            <Button
              {...{
                children: 'Save',
                className:
                  'bg-brand-gradient text-white shadow-card font-medium text-sm py-3 rounded',
                onClick: handleSubmit(onSubmit)
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TerminateEvent;
