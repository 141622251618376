import { Text } from '@/atoms';

const ToastMessage = ({ toastMessage, setToastMessage }) => {
  setTimeout(() => setToastMessage(''), 15000);
  return (
    <div className='w-full justify-center mt-7'>
      <Text
        {...{
          content: toastMessage,
          HtmlTag: 'p',
          className:
            'p-2 rounded font-medium text-sm md:text-sm text-orangeDark bg-whiteBase transition-all ease-in-out duration-300'
        }}
      />
    </div>
  );
};

export default ToastMessage;
