import { useState } from 'react';

import { HamburgerMenu, Text } from '@/atoms';
import { DISPATCH_CTA_STATUS } from '@/config/bid';
import { CART_STATUS } from '@/config/common';
import { GetUiDeviceSurface } from '@/helpers/screenResolutionHelper';
import { PLANNER_PORTAL_URL } from '@/services/connections.service';
import { dispatchCTAStatusLabelMap } from '@/services/tender.service';

const statusClass = {
  Collaborate: 'bg-greenlight text-green',
  Draft: 'bg-gray text-dim-gray',
  Order: 'bg-light-red text-brand'
};

const CartCardHeaderWeb = ({
  cartCardTitle,
  cartName,
  cartNumber,
  cartOrder,
  createdAt,
  createNewTenderAndPublishBids,
  deliveryDate,
  dispatchCTALabel,
  id,
  invokeArchiveCart,
  isActiveTenderAvailable,
  isArchivedCart,
  onClickCloneCart,
  pickupDate,
  router,
  setEditCartSummaryModal,
  setShowMenu,
  setShowPDFDownloadConfirmationModal,
  showEditCartInformationCTA,
  showMenu,
  status,
  updatedAt
}) => {
  const isCartInOrderState = status === CART_STATUS.ORDER;

  const orderedCartCardMenuOptions = [
    {
      alt: 'Bid Responses',
      hoverIcon: 'view-icon-with-frame-red.svg',
      icon: 'view-gray-icon.svg',
      isInactive: !isActiveTenderAvailable,
      label: 'Bid Responses',
      onClick: () => {
        window.open(
          `${PLANNER_PORTAL_URL}/tenders/${id}/bid-responses`,
          '_blank'
        );
      }
    },
    {
      alt: 'Clone Cart',
      hoverIcon: 'clone-cart-gray-icon-red.svg',
      icon: 'clone-cart-gray-icon.svg',
      label: 'Clone Cart',
      onClick: () => onClickCloneCart({ cartId: id, cartStatus: status })
    },
    {
      alt: 'View Order',
      hoverIcon: 'view-icon-with-frame-red.svg',
      icon: 'view-gray-icon.svg',
      label: 'View Order',
      onClick: () => {
        const orderId = cartOrder.order.id;
        router.push(`/my-orders/${orderId}/order-details`);
      }
    }
  ];

  const nonOrderedCartMenuOptions = [
    {
      alt: 'Edit Cart Info',
      hoverIcon: 'edit-red-with-frame.svg',
      icon: 'edit-gray-with-frame.svg',
      label: 'Edit Cart Info',
      onClick: () =>
        setEditCartSummaryModal({
          showModal: true,
          cartSummary: {
            cartName,
            cartNumber,
            createdAt,
            deliveryDate,
            id,
            pickupDate,
            status,
            updatedAt
          }
        })
    },
    {
      alt: 'Create Tender Publish Bids',
      hoverIcon: 'send-icon.svg',
      icon: 'send-icon-gray.svg',
      label: dispatchCTAStatusLabelMap[dispatchCTALabel],
      onClick: () => {
        if (dispatchCTALabel !== DISPATCH_CTA_STATUS.REQUESTING_BID.value) {
          createNewTenderAndPublishBids({
            cartId: id,
            firstTender:
              dispatchCTALabel === DISPATCH_CTA_STATUS.REQUEST_BID.value
          });
        }
      }
    },
    {
      alt: 'Bid Responses',
      hoverIcon: 'view-icon-with-frame-red.svg',
      icon: 'view-gray-icon.svg',
      label: 'Bid Responses',
      onClick: () => {
        window.open(
          `${PLANNER_PORTAL_URL}/tenders/${id}/bid-responses`,
          '_blank'
        );
      }
    },
    {
      alt: 'Download Quote',
      hoverIcon: 'download-red-with-frame.svg',
      icon: 'download-gray-with-frame.svg',
      label: 'Download Quote',
      onClick: () => setShowPDFDownloadConfirmationModal(true)
    },
    {
      alt: 'Clone Cart',
      hoverIcon: 'clone-cart-gray-icon-red.svg',
      icon: 'clone-cart-gray-icon.svg',
      label: 'Clone Cart',
      onClick: () => onClickCloneCart({ cartId: id, cartStatus: status })
    },
    {
      alt: `${isArchivedCart ? 'Unarchive' : 'Archive'} cart`,
      hoverIcon: 'archive-red.svg',
      icon: 'archive-gray.svg',
      label: `${isArchivedCart ? 'Unarchive' : 'Archive'} cart`,
      onClick: () =>
        invokeArchiveCart({ cartId: id, isArchived: !isArchivedCart })
    }
  ];

  const menuOptions = isCartInOrderState
    ? orderedCartCardMenuOptions
    : nonOrderedCartMenuOptions;

  return (
    <div className='flex justify-between text-sm'>
      <Text
        {...{
          content: cartCardTitle,
          className:
            'text-base md:text-2xl overflow-ellipsis whitespace-nowrap w-150 overflow-hidden font-medium inline-block'
        }}
      />
      <div className='flex gap-4'>
        <div
          className={`flex py-1 bg-light-red text-brand px-4 text-base justify-center rounded-full self-center`}
        >
          No. {cartNumber}
        </div>
        <div
          className={`flex py-1 ${statusClass[status]} rounded-full px-4 text-base justify-center self-center`}
        >
          {status}
        </div>
        {showEditCartInformationCTA && (
          <div>
            <HamburgerMenu
              {...{
                menuOptions,
                setShowMenu,
                showMenu
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const CartCardHeaderMobile = ({ cartCardTitle, status, cartNumber }) => (
  <div className='flex flex-col md:flex-row gap-2  md:justify-between text-sm op'>
    <div className='flex md:self-center'>
      <Text {...{ content: cartCardTitle, className: 'text-lg' }} />
    </div>
    <div className='flex gap-4'>
      <div
        className={`flex px-4 py-2 bg-light-red text-brand text-sm rounded-full self-center h-8`}
      >
        No. {cartNumber}
      </div>
      <div className='flex gap-4'>
        <div
          className={`flex px-4 py-2 ${statusClass[status]} text-sm rounded-full self-center h-8`}
        >
          {status}
        </div>
      </div>
    </div>
  </div>
);

const CartCardHeaderMap = {
  mobile: CartCardHeaderMobile,
  web: CartCardHeaderWeb
};

const CartCardHeader = ({
  cartName,
  cartNumber,
  cartOrder,
  createdAt,
  createNewTenderAndPublishBids,
  deliveryDate,
  dispatchCTALabel,
  id,
  invokeArchiveCart,
  isActiveTenderAvailable,
  isArchivedCart,
  onClickCloneCart,
  pickupDate,
  router,
  setEditCartSummaryModal,
  setShowPDFDownloadConfirmationModal,
  showCreateTenderAndDispatchBidsCTA,
  showEditCartInformationCTA,
  status,
  updatedAt
}) => {
  const surface = GetUiDeviceSurface();
  const CartHeader = CartCardHeaderMap[surface];
  const cartCardTitle = cartName || 'NA';
  const [showMenu, setShowMenu] = useState(false);
  return (
    <CartHeader
      {...{
        cartCardTitle,
        cartName,
        cartNumber,
        cartOrder,
        createdAt,
        createNewTenderAndPublishBids,
        deliveryDate,
        dispatchCTALabel,
        id,
        invokeArchiveCart,
        isActiveTenderAvailable,
        isArchivedCart,
        onClickCloneCart,
        pickupDate,
        router,
        setEditCartSummaryModal,
        setShowMenu,
        setShowPDFDownloadConfirmationModal,
        showCreateTenderAndDispatchBidsCTA,
        showEditCartInformationCTA,
        showMenu,
        status,
        updatedAt
      }}
    />
  );
};

export default CartCardHeader;
