import { RichTextEditor, Text } from '@/atoms';

const ExternalNotes = ({ externalNotes, headerLabel = 'Notes' }) =>
  externalNotes && (
    <div className='border border-platinum bg-lightgray p-4 md:p-6 rounded-md'>
      <Text
        {...{
          className: 'font-medium text-base mb-4',
          content: headerLabel
        }}
      />
      <RichTextEditor {...{ readOnly: true, values: externalNotes }} />
    </div>
  );

export default ExternalNotes;
