import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, ModalBody } from 'reactstrap';
import * as yup from 'yup';

import {
  Button,
  CloseIcon,
  DatePicker,
  Dropdown,
  InputField,
  Text
} from '@/atoms';
import { CART_STATUS } from '@/config/common';
import {
  convertToStandardDateFormat,
  getFormattedDate,
  parseDateWithMoment,
  subtractDaysFromDate
} from '@/lib/time';
import { yupValidator } from '@/lib/yup-validator';
import { validateMandatoryFieldsForEPICGenerated } from '@/services/myEvents.service';

const ActionButton = ({ buttonName, className, onClick }) => (
  <Button
    {...{
      children: buttonName,
      className: `py-3 px-2 text-sm font-medium self-end rounded-lg ${className}`,
      onClick,
      width: 'w-32'
    }}
  />
);

const ActionButtonPair = ({ actionButtonPairList }) => (
  <div className='flex gap-2 justify-center'>
    {actionButtonPairList.map(({ buttonName, className, onClick }, id) => (
      <ActionButton
        key={id}
        {...{
          buttonName,
          className,
          onClick
        }}
      />
    ))}
  </div>
);

const LabelValuePair = ({
  className = 'flex w-1/2 gap-2 flex-col border-b border-platinum pb-2 my-5',
  label,
  value
}) => (
  <div className={className}>
    <Text
      content={label}
      className='text-nero'
    />
    <Text
      content={value}
      className='text-dim-gray'
    />
  </div>
);

const DatePickerField = ({
  className = 'flex w-1/2 gap-2 flex-col pb-2 my-5',
  dbName,
  errors,
  label,
  minDate,
  selectedDate,
  setSelectedDate,
  setValue
}) => (
  <div className={className}>
    <Text
      content={label}
      className='text-nero'
    />
    <DatePicker
      {...{ dbName, selectedDate, setSelectedDate, setValue, errors, minDate }}
    />
  </div>
);

const LabelInputPair = ({
  className,
  content,
  dbName,
  defaultValue,
  errors,
  register
}) => (
  <div className='flex gap-2 flex-col border-b border-platinum pb-2 my-5'>
    <Text
      {...{
        content,
        className: 'text-nero'
      }}
    />
    <InputField
      {...{
        className,
        dbName,
        defaultValue,
        errors,
        id: dbName,
        name: dbName,
        register: { ...register(dbName) },
        type: 'text'
      }}
    />
  </div>
);

const CartStatusChangeValidationErrorMessage = ({
  setEditCartSummaryModal,
  userEventId
}) => (
  <div className='bg-error-base-bg text-sm font-medium p-3 rounded mb-5'>
    <Text
      {...{
        className: 'text-error-base',
        content: `⚠️ The respective Event Profile is incomplete.
           Please check and fill in the required details to proceed.
           Details such as - Zendesk ticket ID, Agent name,
            Dummy values etc. Click to view event details.`,
        HtmlTag: 'span'
      }}
    />
    <a
      className='text-blue px-1'
      href={`/my-events/${userEventId}/event-cart-details?viewEPM=true`}
      onClick={() => {
        setEditCartSummaryModal({ showModal: false });
      }}
    >
      click to view event details
    </a>
  </div>
);

const CartSummaryModal = ({
  cartName,
  cartNumber,
  createdAt,
  deliveryDate,
  eventDate,
  id,
  isZendeskIdAgentNameAvailable,
  pickupDate,
  setEditCartSummaryModal,
  setUpdateCartDetail,
  status,
  updatedAt,
  userEvent,
  userEventId
}) => {
  const [selectedDate, setSelectedDate] = useState();
  const dateOfEvent = eventDate && parseDateWithMoment(eventDate);
  const twoDaysBeforeDateOfEvent = subtractDaysFromDate({
    date: eventDate,
    daysToSubtract: 2
  });
  const [cartStatusChangeValidationError, setCartStatusChangeValidationError] =
    useState('');

  const {
    clearErrors,
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    setValue
  } = useForm({
    resolver: yupValidator(
      yup.object().shape({
        cartName: yup
          .string()
          .min(4, 'Cart Name must be at least 4 characters.')
          .max(128, 'Cart Name has a maximum limit of 128 characters.')
          .matches(/^[^\uD800-\uDFFF]+$/g, 'Emojis are not allowed')
          .required('Cart Name is required.'),
        pickupDate: yup
          .date()
          .min(
            yup.ref('deliveryDate'),
            'Pickup Date must be greater than or equal to Delivery Date.'
          )
          .nullable()
      })
    ),

    defaultValues: {
      cartName,
      cartNumber,
      createdAt,
      deliveryDate: deliveryDate ? parseDateWithMoment(deliveryDate) : null,
      pickupDate: pickupDate ? parseDateWithMoment(pickupDate) : null,
      id,
      status,
      updatedAt
    }
  });

  const statusOptions = [
    {
      disabled: status === 'Collaborate',
      label: 'Draft',
      value: 'Draft'
    },
    {
      label: 'Collaborate',
      value: 'Collaborate'
    }
  ];
  const updateCartDetailOnSave = ({
    pickupDate: updatedPickupDate,
    deliveryDate: updatedDeliveryDate,
    ...otherData
  }) => {
    setUpdateCartDetail({
      invoke: true,
      data: {
        pickupDate: updatedPickupDate
          ? getFormattedDate({ date: updatedPickupDate, format: 'YYYY-MM-DD' })
          : null,
        deliveryDate: updatedDeliveryDate
          ? getFormattedDate({
              date: updatedDeliveryDate,
              format: 'YYYY-MM-DD'
            })
          : null,
        ...otherData
      }
    });
    setEditCartSummaryModal({ showModal: false });
  };

  const onSubmit = async ({ status: cartStatus, ...rest }) => {
    if (cartStatus === CART_STATUS.COLLABORATE) {
      return isZendeskIdAgentNameAvailable &&
        validateMandatoryFieldsForEPICGenerated(userEvent)
        ? updateCartDetailOnSave({ status: cartStatus, ...rest })
        : setCartStatusChangeValidationError(true);
    }
    return updateCartDetailOnSave({ status: cartStatus, ...rest });
  };

  const actionButtonPairList = [
    {
      buttonName: 'Cancel',
      className: 'bg-gray text-black',
      onClick: () => setEditCartSummaryModal({ showModal: false })
    },
    {
      buttonName: 'Save',
      className: 'bg-brand-gradient text-white',
      onClick: handleSubmit(onSubmit)
    }
  ];

  const datePickerFields = [
    {
      dbName: 'deliveryDate',
      label: 'Delivery Date',
      minDate: twoDaysBeforeDateOfEvent,
      selectDate: getValues('deliveryDate'),
      selectedDate,
      value: deliveryDate
    },
    {
      dbName: 'pickupDate',
      label: 'Pickup Date',
      minDate: dateOfEvent,
      selectDate: getValues('pickupDate'),
      selectedDate,
      value: pickupDate
    }
  ];

  return (
    <div className='flex gap-5 justify-center  h-screen mx-auto text-sm font-medium'>
      <div className='self-center w-41 z-20 bg-white rounded-lg px-8 py-6 '>
        <CloseIcon
          {...{
            onClick: () => setEditCartSummaryModal({ showModal: false })
          }}
        />
        <Text
          content='Cart Details'
          className='text-lg font-medium text-center'
        />
        <LabelValuePair
          {...{
            label: 'Cart Id',
            value: id,
            className: 'flex gap-2 flex-col border-b border-platinum pb-2 my-5'
          }}
        />
        <LabelInputPair
          {...{
            className: 'border-none padding-left-none',
            content: 'Cart Name',
            dbName: 'cartName',
            defaultValue: cartName,
            errors,
            register
          }}
        />
        <div className='flex gap-10 justify-between'>
          <LabelValuePair
            {...{
              label: 'Cart Number',
              value: cartNumber
            }}
          />
          <div className='flex w-1/2 gap-2 flex-col pb-2 my-5'>
            <Text
              content='Status'
              className='text-nero'
            />
            <Dropdown
              {...{
                clearErrors,
                control,
                dbName: 'status',
                errors,
                getValues,
                name: 'Status',
                options: statusOptions,
                placeholder: 'Select status',
                register,
                setValue,
                onChange: (e) => {
                  setValue('status', e.value);
                  clearErrors();
                }
              }}
            />
          </div>
        </div>
        <div className='flex gap-10 justify-between'>
          <LabelValuePair
            {...{
              label: 'Created At',
              value: convertToStandardDateFormat(createdAt)
            }}
          />
          <LabelValuePair
            {...{
              label: 'Updated At',
              value: convertToStandardDateFormat(updatedAt)
            }}
          />
        </div>
        <div className='flex gap-10 justify-between'>
          {datePickerFields.map(
            ({ label, value, dbName, selectDate, minDate }, index) => (
              <DatePickerField
                key={index}
                {...{
                  dbName,
                  errors,
                  label,
                  minDate,
                  selectedDate: selectDate,
                  setSelectedDate,
                  setValue,
                  value
                }}
              />
            )
          )}
        </div>
        {cartStatusChangeValidationError && (
          <CartStatusChangeValidationErrorMessage
            {...{ userEventId, setEditCartSummaryModal }}
          />
        )}
        <ActionButtonPair {...{ actionButtonPairList }} />
      </div>
    </div>
  );
};

const EditableCartSummaryModal = ({
  editCartSummaryModal: { showModal, cartSummary },
  eventDate,
  isZendeskIdAgentNameAvailable,
  setEditCartSummaryModal,
  setUpdateCartDetail,
  userEvent,
  userEventId
}) => {
  const closeEditCartSummaryModal = () =>
    setEditCartSummaryModal({ showModal: false, cartSummary: null });
  return (
    <Modal
      backdrop={true}
      centered
      fade={false}
      isOpen={showModal}
      size='lg'
      toggle={closeEditCartSummaryModal}
    >
      <ModalBody>
        <CartSummaryModal
          {...{
            ...cartSummary,
            eventDate,
            isZendeskIdAgentNameAvailable,
            setEditCartSummaryModal,
            setUpdateCartDetail,
            userEvent,
            userEventId
          }}
        />
      </ModalBody>
    </Modal>
  );
};

export default EditableCartSummaryModal;
