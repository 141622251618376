import Image from 'next/image';
import { useState } from 'react';

import {
  ImageCarouselModal,
  ImageGalleryModal,
  ProductMediaToggle
} from '@/atoms';
import { CART_ORDER_SOURCE, staticMediaStoreBaseURL } from '@/config/common';
import { getImageURL } from '@/helpers/carousel';
import { useIsMobile } from '@/helpers/screenResolutionHelper';
import { getAuth } from '@/services/identity.service';
import { updateCartItemShowProductMediaToggle } from '@/services/plannerCart.service';

const CoverImage = ({
  coverImage,
  isHostCart,
  setCarouselModalImage,
  setOpenImageGalleryModal,
  isPlannerCartAndProductMediaIsHidden
}) => {
  const [isMobile] = useIsMobile();

  return (
    <a
      onClick={() =>
        isHostCart
          ? setCarouselModalImage(coverImage)
          : setOpenImageGalleryModal(true)
      }
    >
      <div className='absolute top-2 left-1 z-10'>
        <Image
          {...{
            alt: 'view all images',
            className: 'cursor-pointer',
            height: 20,
            layout: 'fixed',
            priority: true,
            src: `${staticMediaStoreBaseURL}/icons/resize-icon.svg`,
            width: 20
          }}
        />
      </div>
      <div
        className={`w-15 md:w-24 border-1 ${
          isPlannerCartAndProductMediaIsHidden
            ? 'border-2 border-orange rounded-md'
            : ''
        }`}
      >
        <Image
          {...{
            alt: 'product-image',
            className: 'rounded-lg',
            height: isMobile ? 59 : 74,
            src: getImageURL(coverImage),
            width: isMobile ? 94 : 122
          }}
        />
      </div>
    </a>
  );
};

const updateImageTitle = (image, isHostCart) => {
  if (isHostCart) return { ...image, imageTitle: '' };
  return {
    ...image,
    imageTitle:
      image.entityType === 'product' ? 'Existing Media' : 'Additional Media'
  };
};

const LineItemMedia = ({
  cartId,
  cartItemId,
  isCartEditable = true,
  isHostCart = false,
  isReadOnly = false,
  itemMediaForCarousel: itemMediaForCarouselRaw = [],
  onDeleteCartItemImage,
  productName,
  productSource = CART_ORDER_SOURCE.CART,
  setCartItemIdForUploadMediaModal,
  showProductMedia,
  unAvailableCartItem = false,
  updateCartItemMediaSortOrder
}) => {
  const userCartId = getAuth()?.id;
  const [openImageGalleryModal, setOpenImageGalleryModal] = useState(false);
  const [carouselModalImage, setCarouselModalImage] = useState(null);
  const [isShowProductMedia, setIsShowProductMedia] = useState(
    Boolean(showProductMedia)
  );

  const itemMediaForCarousel = itemMediaForCarouselRaw.map((image) =>
    updateImageTitle(image, isHostCart)
  );

  if (itemMediaForCarousel.length === 0) {
    itemMediaForCarousel.push({
      url: '/default-images/default-product-image.svg',
      sortOrder: 1
    });
  }

  const additionalLineItemMedia = itemMediaForCarousel.filter(
    ({ entityType }) => ['cartItem', 'orderItem'].includes(entityType)
  );

  const isPlannerCartAndProductMediaIsHidden = !isHostCart && !showProductMedia;

  const updateShowProductMediaToggle = async (showProductMediaFlag) => {
    const { status } = await updateCartItemShowProductMediaToggle({
      cartId,
      cartItemId,
      data: {
        showProductMedia: showProductMediaFlag
      },
      userCartId
    });
    status && setIsShowProductMedia(showProductMediaFlag);
  };

  return (
    <div className='relative'>
      <CoverImage
        {...{
          coverImage: itemMediaForCarousel[0],
          isHostCart,
          setCarouselModalImage,
          setOpenImageGalleryModal,
          isPlannerCartAndProductMediaIsHidden
        }}
      />
      {openImageGalleryModal && (
        <ImageGalleryModal
          {...{
            additionalLineItemMedia,
            cartItemId,
            isCartEditable,
            isReadOnly,
            isShowProductMedia,
            itemMediaForCarousel,
            onClickToggleButton: updateShowProductMediaToggle,
            onDeleteCartItemImage,
            ProductMediaToggleForPlanner: ProductMediaToggle,
            productName,
            productSource,
            setCarouselModalImage,
            setCartItemIdForUploadMediaModal,
            setOpenImageGalleryModal,
            unAvailableCartItem,
            updateCartItemMediaSortOrder
          }}
        />
      )}
      {carouselModalImage && (
        <ImageCarouselModal
          {...{
            images: itemMediaForCarousel,
            modalImage: carouselModalImage,
            openImageModal: setCarouselModalImage
          }}
        />
      )}
    </div>
  );
};

export default LineItemMedia;
