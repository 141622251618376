import { useState } from 'react';

import { RichTextEditor, Text } from '@/atoms';
import { getSerializedRichText } from '@/helpers/editor';
import { GetUiDeviceSurface } from '@/helpers/screenResolutionHelper';

const WEB_NOTE_LENGTH = 120;
const MOBILE_NOTE_LENGTH = 30;

const ShowMoreLessCTA = ({ label, setShowMore, showMore }) => (
  <Text
    {...{
      className: 'cursor-pointer text-xs font-medium text-brand self-center',
      content: label,
      HtmlTag: 'span',
      onClick: (e) => {
        e.stopPropagation();
        setShowMore(!showMore);
      }
    }}
  />
);

const RichTextWithShowMore = ({ productNotes, setShowMore, showMore }) => (
  <div>
    <RichTextEditor
      {...{
        readOnly: true,
        values: productNotes
      }}
    />
    <ShowMoreLessCTA {...{ label: 'Show Less', setShowMore, showMore }} />
  </div>
);

const SerializedProductNote = ({
  NOTE_LENGTH,
  serializedNote,
  setShowMore,
  showMore
}) => (
  <div className='self-top flex'>
    <Text
      {...{
        className: 'max-w-48 md:max-w-180 truncate text-sm pr-2',
        content: serializedNote.slice(0, NOTE_LENGTH),
        HtmlTag: 'span'
      }}
    />
    {serializedNote.length > NOTE_LENGTH && (
      <ShowMoreLessCTA {...{ label: 'Show More', setShowMore, showMore }} />
    )}
  </div>
);

const HostCartProductNotes = ({ productNotes }) => {
  const [showMore, setShowMore] = useState(false);
  const serializedNote = getSerializedRichText(productNotes);
  const surface = GetUiDeviceSurface();

  const noteLengthMapper = {
    mobile: MOBILE_NOTE_LENGTH,
    web: WEB_NOTE_LENGTH
  };
  const NOTE_LENGTH = noteLengthMapper[surface];

  return (
    serializedNote && (
      <div className='flex gap-2 mt-3'>
        <Text
          {...{
            className: 'text-sm font-medium self-top',
            content: 'Details:'
          }}
        />
        {showMore ? (
          <RichTextWithShowMore
            {...{
              productNotes,
              setShowMore,
              showMore
            }}
          />
        ) : (
          <SerializedProductNote
            {...{
              NOTE_LENGTH,
              serializedNote,
              setShowMore,
              showMore
            }}
          />
        )}
      </div>
    )
  );
};

export default HostCartProductNotes;
