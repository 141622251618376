import { Modal } from 'reactstrap';

import { CTA, Text } from '@/atoms';

const ErrorAlertModal = ({
  acceptButtonOnClick,
  acceptButtonText = 'Yes',
  errorMessage,
  isOpenModal,
  rejectButtonOnClick,
  rejectButtonText = 'No'
}) => (
  <Modal
    centered={true}
    isOpen={isOpenModal}
  >
    <div className='flex justify-center h-screen'>
      <div className='flex flex-col self-center bg-white items-center gap-6 justify-center text-center w-108 rounded-lg mx-auto p-6'>
        <Text
          {...{
            className: 'font-medium text-base text-nero',
            content: errorMessage
          }}
        />
        <div className='flex gap-4'>
          {acceptButtonText && (
            <CTA
              {...{
                buttonColor:
                  'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white h-11 px-8 md:py-4 text-base',
                children: 'Yes',
                className: 'w-45',
                onClick: acceptButtonOnClick
              }}
            />
          )}
          {rejectButtonText && (
            <CTA
              {...{
                buttonColor: 'bg-white border border-brand',
                children: 'No',
                className: 'text-brand w-45',
                onClick: rejectButtonOnClick
              }}
            />
          )}
        </div>
      </div>
    </div>
  </Modal>
);

export default ErrorAlertModal;
