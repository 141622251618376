import { RichTextEditor, Text } from '@/atoms';

const ProductDescription = ({ description }) =>
  description && (
    <div className='product-description'>
      <Text
        {...{
          content: 'Description',
          className: 'text-nero text-base md:text-xl font-semibold'
        }}
      />
      <div className='font-light text-xs md:text-sm text-nero mt-4 leading-5'>
        <RichTextEditor
          {...{
            readOnly: true,
            values: description
          }}
        />
      </div>
    </div>
  );

export default ProductDescription;
