export const modeOfConfirmations = [
  { label: 'Email', value: 'EMAIL' },
  { label: 'Prepaid', value: 'PREPAID' },
  { label: 'Purchase Order', value: 'PURCHASE_ORDER' },
  { label: 'WhatsApp', value: 'WHATSAPP' }
];

export const paymentModes = [
  { icon: 'banking.svg', label: 'Bank Transfer', value: 'BANK' },
  { icon: 'cash.svg', label: 'Cash', value: 'CASH' },
  { icon: 'cheque.svg', label: 'Cheque', value: 'CHEQUE' },
  { icon: 'stripe.svg', label: 'Stripe ', value: 'PG_STRIPE' },
  { icon: 'tap.svg', label: 'Tap', value: 'PG_TAP' }
  // { label: 'Others ', value: 'PG_OTHER ', icon: 'other.svg' }
];

export const amountFields = [
  {
    dbName: 'amountReceived',
    inputType: 'number',
    label: 'Amount received',
    placeholder: 'Enter a positive real number without AED'
  },
  {
    dbName: 'orderTotal',
    disable: true,
    inputType: 'number',
    label: 'Grand Total #'
  },
  {
    dbName: 'amountDue',
    disable: true,
    inputType: 'number',
    label: 'Amount Due'
  }
];

export const otherFields = [
  {
    dbName: 'paymentProviderId',
    infoMessage:
      'Cheque#, Cash Receipt Voucher#, Bank Transaction Reference#, or Other Reference# as applicable.',
    inputType: 'text',
    label: 'Reference #',
    placeholder: 'Reference'
  },
  {
    dbName: 'paymentRemarks',
    infoMessage:
      'Capture additional details regarding the payment here, especially when the entire order amount is not received.',
    inputClassName: 'w-full',
    inputType: 'text',
    label: 'Payment Remarks',
    placeholder: 'Payment Remarks'
  }
];
