import { Text } from '@/atoms';
import { PRODUCT_TYPE } from '@/config/common';
import {
  parseFormatPercentValueFromAPI,
  parseFormatPriceValueFromAPI
} from '@/helpers/formatNumber';

const getRackPriceString = ({
  price: { price = 0, minPrice = 0 } = {},
  availableDiscount,
  type: productType
}) => {
  if (productType === PRODUCT_TYPE.QUOTE) {
    return `Price starting from AED ${parseFormatPriceValueFromAPI(
      minPrice || 0
    )}`;
  }

  const isDiscountAvailable =
    availableDiscount?.isActive && availableDiscount?.value;

  const priceAfterDiscount = isDiscountAvailable
    ? price - (price * (availableDiscount?.value || 0)) / 10000
    : price;

  const prefix = `${isDiscountAvailable ? 'AED ' : ''}`;
  const value = parseFormatPriceValueFromAPI(Number(priceAfterDiscount || 0));

  return `${prefix}${value}`;
};

const DiscountPercentWithPriceStrike = ({ unitRackPrice, discountPercent }) => (
  <div className='flex gap-2'>
    <Text
      {...{
        content: `AED ${unitRackPrice}`,
        className: 'line-through text-xs text-dim-gray self-center'
      }}
    />
    <Text
      {...{
        content: `${discountPercent}% off`,
        className: 'text-green text-md font-medium'
      }}
    />
  </div>
);

const RackRateOfUnOrderedCartItem = ({ product, unitRackPrice }) => {
  const rackPriceString = getRackPriceString({
    ...product
  });

  const showDiscountPercentWithPriceStrike =
    product.type === PRODUCT_TYPE.CART && product.availableDiscount?.isActive;

  return (
    <>
      <Text {...{ content: rackPriceString }} />
      {showDiscountPercentWithPriceStrike && (
        <DiscountPercentWithPriceStrike
          {...{
            unitRackPrice: parseFormatPriceValueFromAPI(unitRackPrice),
            discountPercent: parseFormatPercentValueFromAPI(
              product.availableDiscount?.value || 0
            )
          }}
        />
      )}
    </>
  );
};

export default RackRateOfUnOrderedCartItem;
