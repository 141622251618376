import Image from 'next/image';

import { CTA, Text } from '@/atoms';
import { staticMediaStoreBaseURL } from '@/config/common';

const SectionHeadingSaveDiscardCTA = ({
  onClickDiscardChanges,
  onClickSaveChanges,
  isCartEditable
}) => (
  <div className='flex justify-end gap-4 self-center pr-3'>
    <CTA
      {...{
        buttonColor: 'bg-gray',
        children: 'Cancel',
        className: 'py-1 h-9 text-sm font-medium px-6 py-2',
        onClick: onClickDiscardChanges
      }}
    />
    <CTA
      {...{
        buttonColor:
          'bg-gradient-to-r from-light-orange via-dark-orange to-rose text-white',
        children: 'Save',
        className: 'py-1 h-9 text-sm font-medium px-6 py-2',
        onClick: onClickSaveChanges
      }}
    />
  </div>
);

const SectionHeadingViewMode = ({ onClickEdit, title, isCartEditable }) => (
  <div className='flex gap-3'>
    <Text {...{ content: title, className: 'text-lg font-medium pl-2' }} />
    {isCartEditable && (
      <div className='w-6 h-6 flex justify-center self-center bg-neutral rounded-full'>
        <Image
          alt='edit'
          className='self-center cursor-pointer'
          height={12}
          onClick={onClickEdit}
          src={`${staticMediaStoreBaseURL}/icons/edit-icon-orange.svg`}
          width={12}
        />
      </div>
    )}
  </div>
);

const SectionHeadingEditMode = ({
  onClickDiscardChanges,
  onClickSaveChanges,
  title
}) => (
  <>
    <div className='flex gap-3'>
      <Text {...{ content: title, className: 'text-lg font-medium pl-2' }} />
    </div>
    <SectionHeadingSaveDiscardCTA
      {...{ onClickDiscardChanges, onClickSaveChanges }}
    />
  </>
);

const SectionHeadingMap = {
  VIEW: SectionHeadingViewMode,
  EDIT: SectionHeadingEditMode
};

const CartSectionHeading = ({
  isCartEditable,
  mode,
  onClickDiscardChanges,
  onClickEdit,
  onClickSaveChanges,
  title
}) => {
  const SectionHeading = SectionHeadingMap[mode];
  return (
    <div className='flex justify-between'>
      <SectionHeading
        {...{
          isCartEditable,
          onClickDiscardChanges,
          onClickEdit,
          onClickSaveChanges,
          title
        }}
      />
    </div>
  );
};

export default CartSectionHeading;
