import { RichTextEditor, Text } from '@/atoms';
import { onChangeOfNotes } from '@/services/plannerCart.service';

const CartItemNotes = ({
  errors,
  productNotes,
  priceNotes,
  status,
  setIsUnitListedPriceNotEditable,
  setValue,
  cartItemViewEditMode
}) => {
  const notesFields = [
    {
      additionalStyle: `${
        !cartItemViewEditMode ? 'bg-white' : 'bg-ghost-white'
      } p-2`,
      dbName: 'productNotes',
      label: 'Product Notes',
      notes: productNotes
    },
    {
      additionalStyle: 'bg-highlight p-2',
      itemStatus: status,
      dbName: 'priceNotes',
      label: 'Price Notes',
      notes: priceNotes
    }
  ];

  return (
    <div className='flex gap-20'>
      {notesFields.map(
        ({ additionalStyle, dbName, itemStatus, label, notes }, index) => {
          const isPriceNote = dbName === 'priceNotes';

          const validationErrorMessage = errors?.[dbName]?.message;
          return (
            <div
              key={`${index}-${dbName}`}
              className='w-1/2 flex'
            >
              <Text
                {...{
                  content: label,
                  className: 'text-sm font-medium self-center flex w-40'
                }}
              />
              <div
                className={`flex-1 border border-platinum rounded-md ${
                  !cartItemViewEditMode ? 'h-60' : 'h-44'
                } ${additionalStyle}`}
              >
                <RichTextEditor
                  {...{
                    dbKey: dbName,
                    editorStyle: 'h-40 overflow-y-auto',
                    readOnly: cartItemViewEditMode,
                    setValue: (name, value) =>
                      onChangeOfNotes({
                        inputtedNotes: value,
                        setValue,
                        isPriceNote,
                        itemStatus,
                        dbName,
                        setIsUnitListedPriceNotEditable
                      }),
                    values: notes
                  }}
                />
                {validationErrorMessage && (
                  <div className='text-xs text-red-500'>
                    {validationErrorMessage}
                  </div>
                )}
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default CartItemNotes;
