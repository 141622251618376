import { Text } from '@/atoms';

const ProductInfo = ({ name, shortDescription }) => (
  <div className='flex flex-col gap-4'>
    <Text
      {...{
        content: name,
        className: 'text-xl md:text-3xl font-semibold text-nero leading-6'
      }}
    />
    <Text
      {...{
        content: shortDescription,
        className: 'text-brand text-sm md:text-base font-medium'
      }}
    />
  </div>
);

export default ProductInfo;
