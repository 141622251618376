import Image from 'next/image';
import { useState } from 'react';
import Select from 'react-select';
import { Col } from 'reactstrap';

import { staticMediaStoreBaseURL } from '@/config/common';

const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0)',
    transition: '250ms'
  }),
  control: (base) => ({
    ...base,
    border: 'none',
    boxShadow: 'none',
    '&:hover': {
      border: 'none'
    }
  }),
  option: (base, { isFocused, isSelected }) => ({
    ...base,
    backgroundColor: isFocused ? '#FDF1EE' : 'white',
    color: isSelected || '#000'
  })
};

const PlaceholderComponent = ({ placeholderText }) => (
  <div className='flex gap-2'>
    <Image
      src={`${staticMediaStoreBaseURL}/icons/search_new.svg`}
      height={18}
      width={18}
      alt='search icon'
    />
    <div>{placeholderText}</div>
  </div>
);

const EnumController = ({
  clearErrors,
  dbName,
  disabled,
  options,
  placeholderLabel,
  register,
  selectedOption,
  setValue
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const customPlaceholder = isOpen ? (
    <PlaceholderComponent
      {...{ placeholderText: `Search ${placeholderLabel}` }}
    />
  ) : (
    `Select ${placeholderLabel}`
  );

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Select
      {...{
        ...register(dbName),
        onChange: (e) => {
          setValue(dbName, e.value);
          clearErrors();
        },
        options,
        value: selectedOption,
        defaultValue: selectedOption,
        classNamePrefix: 'select2-selection form-select',
        placeholder: customPlaceholder,
        onMenuOpen: toggleMenu,
        onMenuClose: toggleMenu,
        isDisabled: disabled,
        styles: customStyles
      }}
    />
  );
};

const SelectFormInput = ({
  clearErrors,
  control,
  dbName,
  disabled = false,
  errors,
  getValues,
  name = '',
  placeholderLabel = '',
  register,
  setValue,
  values
}) => {
  const selectedValue = getValues(dbName);
  const options = values.map((value) => ({
    label: value.name,
    value: value.id
  }));
  const selectedOption = options.find((obj) => obj.value === selectedValue);
  return (
    <>
      <Col className='multi-select-epm'>
        <EnumController
          {...{
            clearErrors,
            control,
            dbName,
            disabled,
            name,
            options,
            placeholderLabel,
            register,
            selectedOption,
            setValue
          }}
        />
      </Col>
      {errors[dbName] && (
        <div className='mt-1 w-full text-red-500 text-xs font-medium capitalize invalid-feedback'>
          {errors[dbName].message}
        </div>
      )}
    </>
  );
};

export default SelectFormInput;
