import { Text } from '@/atoms';

const PriceSummaryFooter = ({
  absoluteMargin,
  grandTotal,
  haflaPrice,
  margin,
  partnerPrice
}) => (
  <div className='w-full'>
    <div className='flex gap-5 text-sm text-nero font-medium py-3 px-4 border-b border-t border-platinum bg-planner-cart-head rounded-b'>
      <div className='w-108 flex gap-3 self-center'>
        <Text
          {...{
            content: grandTotal,
            className: 'self-center flex-1 flex'
          }}
        />
      </div>
      <Text {...{ content: haflaPrice, className: 'w-40 self-center' }} />
      <Text {...{ content: partnerPrice, className: 'w-40 self-center' }} />
      <Text {...{ content: absoluteMargin, className: 'w-56 self-center' }} />
      <Text
        {...{
          content: `${margin} %`,
          className: `w-40 self-center ${
            Number(margin) > 0 ? 'text-green' : 'text-red-500'
          }`
        }}
      />
    </div>
  </div>
);

export default PriceSummaryFooter;
