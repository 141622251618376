import { InputField, Text } from '@/atoms';
import { VIEW_EDIT_MODE } from '@/config/plannerCart';

const ViewSummary = ({ haflaPrice }) => (
  <Text {...{ content: haflaPrice, className: 'w-40 self-center' }} />
);

const EditSummary = ({ register, rowTitle, dbName, getValues }) => (
  <InputField
    {...{
      baseClass: 'border px-2 py-1 text-nero text-sm',
      className: 'rounded-lg w-20',
      dbName: dbName || rowTitle,
      defaultValue: getValues(dbName) || 0,
      inputGroup: 'relative -left-5',
      register: register(dbName)
    }}
  />
);
const ViewEditSummaryMode = {
  VIEW: ViewSummary,
  EDIT: EditSummary
};

const SummaryRow = ({
  absoluteMargin,
  dbName,
  getValues,
  haflaPrice,
  margin,
  mode = VIEW_EDIT_MODE.VIEW,
  partnerPrice,
  register,
  rowTitle
}) => {
  const Summary = ViewEditSummaryMode[mode];
  const marginString =
    margin === '-' || Number(margin) === 0 ? margin : `${margin} %`;
  return (
    <div className='w-full'>
      <div className='flex gap-5 text-sm text-nero font-light py-3 px-4 bg-lightgray'>
        <div className='w-108 flex gap-3 self-center'>
          <Text
            {...{
              content: rowTitle,
              className: 'self-center flex-1 flex'
            }}
          />
        </div>
        <Summary {...{ rowTitle, haflaPrice, register, dbName, getValues }} />
        <Text {...{ content: partnerPrice, className: 'w-40 self-center' }} />
        <Text {...{ content: absoluteMargin, className: 'w-56 self-center' }} />
        <Text
          {...{
            content: marginString,
            className: 'w-40 self-center'
          }}
        />
      </div>
    </div>
  );
};

export default SummaryRow;
