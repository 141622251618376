import { useRouter } from 'next/router';

import { Container, RibbonButton } from '@/atoms';
import {
  GTM_EVENTS_CTA_NAME,
  PAGE_NAME,
  captureGTMEventCTAClick
} from '@/lib/gtm';

const BecomePartner = ({
  title,
  buttonTitle,
  buttonUrl,
  subTitle,
  isOpenInSamePage
}) => {
  const Router = useRouter();
  return (
    <Container
      className='w-full justify-around bg-nero text-left flex items-center height-container'
    >
      <div>
        <div className='font-bold text-white'>{title}</div>
        {subTitle && <div className='text-base text-white'>{subTitle}</div>}
      </div>
      <RibbonButton
        className='rounded-full uppercase px-6 md:py-3'
        onClick={() => {
          captureGTMEventCTAClick({
            ctaName: GTM_EVENTS_CTA_NAME.BECOME_PARTNER,
            pageName: PAGE_NAME.HOME_PAGE.label
          });

          return isOpenInSamePage
            ? Router.push(buttonUrl)
            : window.open(buttonUrl, '_blank');
        }}
      >
        {buttonTitle}
        <span> →</span>
      </RibbonButton>
    </Container>
  );
};

export default BecomePartner;
