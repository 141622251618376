import { Text } from '@/atoms';
import { CART_ITEM_LIST_HEADERS } from '@/config/plannerCart';

const CartItemListHeader = ({ resequenceCartItems }) => (
  <div className='w-full'>
    <div className='flex gap-5 text-xs font-semibold text-silver uppercase py-5 px-4 rounded-t-lg border border-platinum bg-planner-cart-head'>
      {CART_ITEM_LIST_HEADERS.map(({ label, style }, index) => {
        const showActionColumn =
          !resequenceCartItems || (resequenceCartItems && label !== 'ACTION');
        return (
          showActionColumn && (
            <Text
              key={index}
              {...{ content: label, className: style }}
            />
          )
        );
      })}
    </div>
  </div>
);

export default CartItemListHeader;
